<template>
  <v-expand-transition>
    <v-card flat class="px-4" color="lightgray" v-show="display">
      <v-card-actions class="pa-0">
        <v-spacer></v-spacer>
        <v-tooltip bottom color="darkblue">
          <template v-slot:activator="{ on }">
            <v-btn
              depressed
              outlined
              style="background-color: #ffffff !important;"
              small
              class="mr-3"
              @click="getAll"
              v-on="on"
            >
              <v-icon>all_inclusive</v-icon>
            </v-btn>
          </template>
          <span>{{ $tc("global.puxar-tudo") }}</span>
        </v-tooltip>

        <v-tooltip bottom color="darkblue">
          <template v-slot:activator="{ on }">
            <v-btn
              depressed
              small
              :outlined="!queryEmpty"
              :style="queryEmpty ? '' : 'background-color: #ffffff !important;'"
              class="mr-3"
              @click="resetQuery"
              :disabled="queryEmpty"
              v-on="on"
            >
              <v-icon color="buttons2">settings_backup_restore</v-icon>
            </v-btn>
          </template>
          <span>{{ $tc("global.resetar") }}</span>
        </v-tooltip>

        <v-tooltip bottom color="darkblue">
          <template v-slot:activator="{ on }">
            <v-btn
              depressed
              small
              :outlined="valid"
              :style="!valid ? '' : 'background-color: #ffffff !important;'"
              @click="filtrar"
              :disabled="!valid"
              v-on="on"
            >
              <v-icon color="buttons">filter_list</v-icon>
            </v-btn>
          </template>
          <span>{{ $tc("global.filtrar") }}</span>
        </v-tooltip>
      </v-card-actions>
      <v-card-text class="pa-0">
        <v-form ref="form">
          <v-row>
            <!-- Cliente -->
            <v-col cols="12" md="4" v-if="P_cliente">
              <v-autocomplete
                v-model="filtros.cliente"
                :items="clientes"
                :search-input.sync="search"
                @keyup="buscaClientes"
                clearable
                dense
                solo
                flat
                outlined
                hide-details
                hide-no-data
                hide-selected
                item-text="nome"
                item-value="id"
                label="Cliente"
                prepend-inner-icon="search"
              ></v-autocomplete>
            </v-col>

            <!-- dataFrom e dataTo -->
            <v-col cols="12" md="4" v-if="P_periodo">
              <v-menu
                ref="menuDate"
                v-model="menuDate"
                offset-y
                transition="scale-transition"
                :close-on-content-click="false"
                max-width="550px"
              >
                <template v-slot:activator="{ on }">
                  <v-text-field
                    :value="dataFromTo"
                    ref="dateFromTo"
                    label="Periodo"
                    append-icon="event"
                    dense
                    clearable
                    @click:clear="clearPeriodo('dataFrom', 'dataTo')"
                    readonly
                    solo
                    flat
                    outlined
                    hide-details
                    v-on="on"
                  ></v-text-field>
                </template>
                <v-card>
                  <v-row>
                    <v-col cols="12" md="4">
                      <v-list dense nav>
                        <v-list-item @click="dateSelection(1, 'today')">
                          <v-list-item-icon>
                            <v-icon>keyboard_arrow_right</v-icon>
                          </v-list-item-icon>
                          <v-list-item-content>
                            <v-list-item-title>Hoje</v-list-item-title>
                          </v-list-item-content>
                        </v-list-item>
                        <v-list-item @click="dateSelection(1, 'week')">
                          <v-list-item-icon>
                            <v-icon>keyboard_arrow_right</v-icon>
                          </v-list-item-icon>
                          <v-list-item-content>
                            <v-list-item-title>Uma Semana</v-list-item-title>
                          </v-list-item-content>
                        </v-list-item>
                        <v-list-item @click="dateSelection(1, 'month')">
                          <v-list-item-icon>
                            <v-icon>keyboard_arrow_right</v-icon>
                          </v-list-item-icon>
                          <v-list-item-content>
                            <v-list-item-title>Um mes</v-list-item-title>
                          </v-list-item-content>
                        </v-list-item>
                        <v-list-item @click="dateSelection(2, 'month')">
                          <v-list-item-icon>
                            <v-icon>keyboard_arrow_right</v-icon>
                          </v-list-item-icon>
                          <v-list-item-content>
                            <v-list-item-title>Dois meses</v-list-item-title>
                          </v-list-item-content>
                        </v-list-item>
                        <v-list-item @click="dateSelection(3, 'month')">
                          <v-list-item-icon>
                            <v-icon>keyboard_arrow_right</v-icon>
                          </v-list-item-icon>
                          <v-list-item-content>
                            <v-list-item-title>Tres meses</v-list-item-title>
                          </v-list-item-content>
                        </v-list-item>
                        <v-list-item @click="dateSelection(1, 'year')">
                          <v-list-item-icon>
                            <v-icon>keyboard_arrow_right</v-icon>
                          </v-list-item-icon>
                          <v-list-item-content>
                            <v-list-item-title>Um ano</v-list-item-title>
                          </v-list-item-content>
                        </v-list-item>
                      </v-list>
                    </v-col>
                    <v-col cols="12" md="8">
                      <v-date-picker
                        class="elevation-0"
                        full-width
                        no-title
                        range
                        v-model="dates"
                      >
                        <v-spacer></v-spacer>
                        <v-btn text color="primary" @click="menuDate = false">
                          Cancel
                        </v-btn>
                        <v-btn
                          text
                          color="primary"
                          @click="$refs.menuDate.save(dates)"
                        >
                          OK
                        </v-btn>
                      </v-date-picker>
                    </v-col>
                  </v-row>
                </v-card>
              </v-menu>
            </v-col>

            <!-- Cidades -->
            <v-col cols="12" md="4" v-if="P_cidade">
              <v-combobox
                class="ma-0"
                height="40px"
                :items="cidades"
                label="Cidade"
                clearable
                @click:clear="clearBox('cidadeSelected', 'cidade')"
                item-text="nome"
                item-value="id"
                dense
                outlined
                solo
                flat
                hide-details
                v-model="cidadeSelected"
              ></v-combobox>
            </v-col>

            <!-- Depositos -->
            <v-col cols="12" md="4" v-if="P_depositos">
              <v-combobox
                class="ma-0"
                height="40px"
                :items="depositos"
                label="Depositos"
                clearable
                @click:clear="clearBox('depositoSelected', 'deposito')"
                item-text="descricao"
                item-value="id"
                dense
                outlined
                solo
                flat
                hide-details
                v-model="depositoSelected"
              ></v-combobox>
            </v-col>

            <!-- Campo Data -->
            <v-col cols="12" md="4" v-if="P_campoData && P_campoDataArray.length">
              <v-select
                v-model="campoData"
                :items="P_campoDataArray"
                label="Campo Data"
                 dense
                outlined
                solo
                flat
                hide-details
                clearable
                @click:clear="clearCampoData"
              ></v-select>
            </v-col>

            <!-- Tipos -->
            <v-col cols="12" md="4" v-if="P_tipos">
              <v-combobox
                class="ma-0"
                height="40px"
                :items="tipos"
                label="Tipos"
                clearable
                @click:clear="clearBox('tipoSelected', 'tipo')"
                item-text="descricao"
                item-value="id"
                dense
                outlined
                solo
                flat
                hide-details
                v-model="tipoSelected"
              ></v-combobox>
            </v-col>

            <!-- Classificacoes -->
            <v-col cols="12" md="4" v-if="P_classificacoes">
              <v-combobox
                class="ma-0"
                height="40px"
                :items="classificacoes"
                label="Classificacoes"
                clearable
                @click:clear="
                  clearBox('classificacaoSelected', 'classificacao')
                "
                item-text="descricao"
                item-value="id"
                dense
                outlined
                solo
                flat
                hide-details
                v-model="classificacaoSelected"
              ></v-combobox>
            </v-col>

            <!-- Produto Classificacoes -->
            <v-col cols="12" md="4" v-if="P_produto_classificacao">
              <v-combobox
                class="ma-0"
                height="40px"
                :items="produtoClassificacoes"
                label="Classificacoes"
                clearable
                @click:clear="
                  clearBox(
                    'produtoClassificacaoSelected',
                    'produto_classificacao'
                  )
                "
                item-text="descricao"
                item-value="id"
                dense
                outlined
                solo
                flat
                hide-details
                v-model="produtoClassificacaoSelected"
              ></v-combobox>
            </v-col>

            <!-- Status -->
            <v-col cols="12" md="4" v-if="P_status && P_statusArray.length">
              <v-select
                v-model="filtros.status"
                :items="P_statusArray"
                outlined
                solo
                flat
                clearable
                dense
                hide-details
                label="Status"
              ></v-select>
            </v-col>

            <!-- Data Evento Agendado -->
            <v-col cols="12" md="4" v-if="P_evento_agendado">
              <v-menu
                v-model="menuEventoAgendado"
                :close-on-content-click="false"
                :nudge-right="40"
                transition="scale-transition"
                offset-y
                min-width="290px"
              >
                <template v-slot:activator="{ on }">
                  <v-text-field
                    :value="eventoAgendadoFormatted"
                    label="Data Agendado"
                    append-icon="event"
                    dense
                    outlined
                    solo
                    flat
                    clearable
                    readonly
                    hide-details
                    v-on="on"
                  ></v-text-field>
                </template>
                <v-date-picker
                  no-title
                  v-model="filtros.evento_agendado"
                  @input="menuEventoAgendado = false"
                ></v-date-picker>
              </v-menu>
            </v-col>

            <!-- Data Evento Concluido -->
            <v-col cols="12" md="4" v-if="P_evento_concluido">
              <v-menu
                v-model="menuEventoConcluido"
                :close-on-content-click="false"
                :nudge-right="40"
                transition="scale-transition"
                offset-y
                min-width="290px"
              >
                <template v-slot:activator="{ on }">
                  <v-text-field
                    :value="eventoConcluidoFormatted"
                    label="Data Concluido"
                    append-icon="event"
                    dense
                    outlined
                    solo
                    flat
                    clearable
                    readonly
                    hide-details
                    v-on="on"
                  ></v-text-field>
                </template>
                <v-date-picker
                  no-title
                  v-model="filtros.evento_concluido"
                  @input="menuEventoConcluido = false"
                ></v-date-picker>
              </v-menu>
            </v-col>
          </v-row>
        </v-form>
      </v-card-text>
    </v-card>
  </v-expand-transition>
</template>

<script>
import { fetchCidades } from "@/services/api/cidades.api.js";
import { getListClientes } from "@/services/api/clientes.api.js";
import { getClassificacoes } from "@/services/api/classificacoes.api.js";
import { getEventosTipos } from "@/services/api/eventos-tipos.api.js";
import { fetchDepositos } from "@/services/api/produtos.api.js";
import { getAllProClass } from "@/services/api/produtos.api.js";

export default {
  name: "Filtro",
  props: {
    display: {
      type: Boolean,
      default: false,
    },
    P_evento_agendado: {
      type: Boolean,
      default: false,
    },
    P_evento_concluido: {
      type: Boolean,
      default: false,
    },
    P_cidade: {
      type: Boolean,
      default: false,
    },
    P_periodo: {
      type: Boolean,
      default: false,
    },
    P_status: {
      type: Boolean,
      default: false,
    },
    P_statusArray: {
      type: Array,
    },
    P_campoData: {
      type: Boolean,
      default: false,
    },
    P_campoDataArray: {
      type: Array,
    },
    P_cliente: {
      type: Boolean,
      default: false,
    },
    P_classificacoes: {
      type: Boolean,
      default: false,
    },
    P_tipos: {
      type: Boolean,
      default: false,
    },
    P_depositos: {
      type: Boolean,
      default: false,
    },
    P_produto_classificacao: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      menuDate: false,
      panel: null,
      dates: [],
      cidades: [],
      clientes: [],
      classificacoes: [],
      depositos: [],
      tipos: [],
      produtoClassificacoes: [],
      tipoSelected: null,
      classificacaoSelected: null,
      cidadeSelected: null,
      depositoSelected: null,
      produtoClassificacaoSelected: null,
      search: "",
      carregarTudo: false,
      filtros: {
        dataFrom: null,
        dataTo: null,
        cidade: null,
        deposito: null,
        status: null,
        cliente: null,
        classificacao: null,
        tipo: null,
        produto_classificacao: null,
        evento_agendado: null,
        evento_concluido: null,
        campo_data: null,
      },
      menuEventoAgendado: false,
      menuEventoConcluido: false,
      campoData: null,
    };
  },
  computed: {
    dataFromTo() {
      if (this.$moment(this.dates[1]).isBefore(this.dates[0])) {
        let dateFrom = this.$moment(this.dates[1]).format("DD/MM/YYYY");
        let dateTo = this.$moment(this.dates[0]).format("DD/MM/YYYY");

        return `De ${dateFrom} à ${dateTo}`;
      }
      let dateFrom = this.$moment(this.dates[0]).format("DD/MM/YYYY");
      let dateTo = this.$moment(this.dates[1]).format("DD/MM/YYYY");

      if (dateFrom === dateTo) {
        return `${dateFrom}`;
      } else {
        return `De ${dateFrom} à ${dateTo}`;
      }
    },
    queryEmpty() {
      return this.lodash.isEmpty(this.$route.query);
    },
    valid() {
      let value = false;
      for (let key in this.filtros) {
        if (this.filtros[key] != null) {
          value = true;
        }
      }
      return value;
    },
    eventoAgendadoFormatted() {
      return this.filtros.evento_agendado
        ? this.$moment(this.filtros.evento_agendado).format("DD/MM/YYYY")
        : "";
    },
    eventoConcluidoFormatted() {
      return this.filtros.evento_concluido
        ? this.$moment(this.filtros.evento_concluido).format("DD/MM/YYYY")
        : "";
    },
    campoDataQuery() {
      return this.$route.query.campoData ? this.$route.query.campoData : null;
    },
  },
  watch: {
    dates() {
      if (this.$moment(this.dates[1]).isBefore(this.dates[0])) {
        let dateFrom = this.dates[1];
        let dateTo = this.dates[0];
        this.dates[0] = dateFrom;
        this.dates[1] = dateTo;
      }

      this.filtros.dataFrom = this.dates[0];
      this.filtros.dataTo = this.dates[1];
    },
    cidadeSelected() {
      if (!this.cidadeSelected) return;
      this.filtros.cidade = this.cidadeSelected.id;
    },
    depositoSelected() {
      if (!this.depositoSelected) return;
      this.filtros.deposito = this.depositoSelected.id;
    },
    tipoSelected() {
      if (!this.tipoSelected) return;
      this.filtros.tipo = this.tipoSelected.id;
    },
    classificacaoSelected() {
      if (!this.classificacaoSelected) return;
      this.filtros.classificacao = this.classificacaoSelected.id;
    },
    produtoClassificacaoSelected() {
      if (!this.produtoClassificacaoSelected) return;
      this.filtros.produto_classificacao = this.produtoClassificacaoSelected.id;
    },
    campoData() {
      if (this.campoData) {
        this.filtros.campo_data = this.campoData;
      }
    },
    campoDataQuery: {
      handler() {
        if (this.campoDataQuery) {
          this.campoData = this.campoDataQuery;
        }
      },
      immediate: true,
    },
  },
  methods: {
    clearCampoData() {
      this.filtros.campo_data = null; 
      this.campoData = null;
    },
    getDepositos() {
      this.$Progress.start();
      fetchDepositos()
        .then((response) => {
          this.depositos = response;
          this.$Progress.finish();
        })
        .catch((error) => {
          console.log(error);
          this.$Progress.fail();
        });
    },
    filtrar() {
      let filtro = {};
      for (let key in this.filtros) {
        if (this.filtros[key] != null) {
          filtro[key] = this.filtros[key];
        }
      }
      if (this.lodash.isEqual(this.$route.query, filtro)) return;
      this.$store.commit("UPDATE_QUERY", filtro);
      this.$router.push({ query: filtro });
    },
    getAll() {
      if (this.lodash.isEqual(this.$route.query, { load: "all" })) return;
      this.$router.push({ query: { load: "all" } });
      this.$store.commit("UPDATE_QUERY", null);
    },
    resetQuery() {
      this.$router.replace({ query: null });
      this.$store.commit("UPDATE_QUERY", null);
      this.search = "";
      this.$refs.form.reset();
      for (let key in this.filtros) {
        this.filtros[key] = null;
      }
    },
    dateSelection(range, type) {
      let dates = [];
      if (type === "today") {
        dates.push(this.$moment().format("YYYY-MM-DD"));
      } else {
        dates.push(
          this.$moment()
            .subtract(range, type)
            .format("YYYY-MM-DD")
        );
        dates.push(this.$moment().format("YYYY-MM-DD"));
      }
      this.dates = dates;
    },
    getCidades() {
      fetchCidades()
        .then((response) => {
          this.cidades = response;
          if (this.$route.query.cidade) {
            let cidade = this.cidades.find(
              (item) => item.id == this.$route.query.cidade
            );
            this.cidadeSelected = cidade;
          }
        })
        .catch((error) => console.log(error));
    },
    getClientes(filtros) {
      this.clientes = null;
      getListClientes(filtros)
        .then((response) => {
          this.clientes = response;
        })
        .catch((error) => console.log(error));
    },
    buscaClientes(event) {
      if (this.search) {
        if (this.search.length > 3 && event.key != "Backspace") {
          this.getClientes(`?search=${this.search}`);
        }
      }
    },
    getEventoClassificacoes() {
      getClassificacoes()
        .then((response) => {
          this.classificacoes = response;
          if (this.$route.query.classificacao) {
            let classificacao = this.classificacoes.find(
              (item) => item.id == this.$route.query.classificacao
            );
            this.classificacaoSelected = classificacao;
          }
        })
        .catch((error) => console.log(error));
    },
    getEventoTipos() {
      getEventosTipos()
        .then((response) => {
          this.tipos = response;
          if (this.$route.query.tipo) {
            let tipo = this.tipos.find(
              (item) => item.id == this.$route.query.tipo
            );
            this.tipoSelected = tipo;
          }
        })
        .catch((error) => console.log(error));
    },
    clearBox(selected, filtro) {
      this[selected] = null;
      this.filtros[filtro] = null;
    },
    clearPeriodo(dataFrom, dataTo) {
      this.filtros[dataFrom] = null;
      this.filtros[dataTo] = null;
    },
    updateFiltroOnLoad() {
      if (!this.lodash.isEmpty(this.$route.query)) {
        for (let key in this.$route.query) {
          if (key === "status" || key === "cliente") {
            this.filtros[key] = Number(this.$route.query[key]);
          } else {
            this.filtros[key] = this.$route.query[key];
          }
        }
        this.panel = 0;
      }
    },
    getProClass() {
      getAllProClass()
        .then((response) => {
          this.produtoClassificacoes = response;
          if (this.$route.query.produto_classificacao) {
            let produtoClassificacao = this.produtoClassificacoes.find(
              (item) => item.id == this.$route.query.produto_classificacao
            );
            this.produtoClassificacaoSelected = produtoClassificacao;
          }
        })
        .catch((error) => {
          console.log(error);
        });
    },
  },
  created() {
    // this.dates[0] = this.$moment().subtract(7, "days").format("YYYY-MM-DD");
    // this.dates[1] = this.$moment().format("YYYY-MM-DD");
  },
  mounted() {
    this.updateFiltroOnLoad();
    if (this.$route.query.cliente) {
      this.getClientes(`?search=${this.$route.query.cliente}`);
    }
    if (this.P_cidade) {
      this.getCidades();
    }
    if (this.P_depositos) {
      this.getDepositos();
    }
    if (this.P_classificacoes) {
      this.getEventoClassificacoes();
    }
    if (this.P_tipos) {
      this.getEventoTipos();
    }
    if (this.P_produto_classificacao) {
      this.getProClass();
    }
  },
};
</script>

<style lang="scss" scoped></style>
